
<template>  
  <div class=''>首页</div>
</template>

<script>

export default {
 components: {},
 data() {
   return {
 
   };
 },

 methods: {
 
 },

 created() {
 
 },

 mounted() {
 
 },

 }
</script>
<style lang='less' scoped>

</style>